.section-title {
  font-size: 15px;
  font-weight: 500;
}

.section-description {
  font-size: 14px;
}

.challenge-requirement-container {
    padding: 20px 20px 0;
    background-color: #fff;

    .challenge-requirement-title {
      font-size: 20px;
      font-weight: 600;
      margin: 10px 0;
    }

    .challenge-requirement-inputs {
      margin: 20px 0;
      display: flex;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }
    }
  }

  .add-challenge {
    padding: 10px 20px;
    background-color: #fff;
    margin: 20px 0;


    .add-inputs {
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .challenge-dropdown {
        flex: 1;
      }

      .custom-option {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 30px;
          height: 35px;
          margin: 0 20px;
        }
      }

      .button-element {
        margin: auto 0 auto 12px;
        width: 40px;

        min-width: 40px;

        button {
          padding: 0;
          color: rgba(207, 27, 27, 0);
        }
      }
    }
  }

  .starter-challenges-display {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .starter-challenges {
      padding: 0 0 35px;
      .user-challenges-hidden-header {
        height: 56px;
        padding: 0 20px;
        background-color: #eaf2fc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        border-radius: 8px;
        &:hover {
          cursor: pointer;
          .user-challenges-header-text,
          user-challenges-header-button {
            opacity: 0.8;
          }
        }
      }
      .user-challenges-shown-header {
        height: 56px;
        padding: 0 20px;
        background-color: #eaf2fc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        border-radius: 8px 8px 0 0;
        &:hover {
          cursor: pointer;
          .user-challenges-header-text,
          user-challenges-header-button {
            opacity: 0.8;
          }
        }
      }

      .user-challenges-header-text {
        font-size: 18px;
        font-weight: 500;
      }

      .show-hide-challenges-button {
        width: 30px;
        height: 30px;
        transition: all 0.2s ease-in-out;
      }

      .user-challenges-list-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f3f8ffe7;
        
        .empty-list-description {
          font-size: 15px;
          padding: 10px 0;
          text-align: center;          
        }
      }

      ul.user-challenges-list {
        padding: 16px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 25%);
        width: 100%;

        >li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 10px;
          border-radius: 3px;
          min-height: 200px;
          height: max-content;
          border-radius: 12px;
          background-color: #fff;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

          .challenge-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 50%;
            text-align: center;

            img {
              height: 60px;
              aspect-ratio: 1;
              margin: 0 15px
            }
          }

        transition: 0.3s all ease-in-out;
        &:hover {
            transform: scale(1.05);
            cursor: pointer;
          }
        }
      }

      .challenge-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        width: 80%;
        border-radius: 10px;
      }

      .button-element {
        .background {
          background-color: rgba(200, 0, 50, 1);
        }
      }
    }
  }